.CompanyOverview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 60px;
}

@media only screen and (min-width: 715px) {
    .CompanyOverview {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
}