.ThreeDMenuL {
    margin: 5px;
    z-index: 1000;
    position: fixed;
    top: 75px;
    right: 15px;
}

.ThreeDMenuM {
    margin: 5px;
    z-index: 1000;
    position: fixed;
    top: 56px;
    right: 0;
}

.Text {
    padding-right: 5px;
    padding-top: 1px;
}